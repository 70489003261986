import { Image, Nav } from 'react-bootstrap';
import logo from '../assets/brr-logo-white.png';

function BRRFooter({ title }) {
    return (
        <Nav className='d-flex flex-md-row align-items-md-start justify-content-center py-5 w-100 text-white bg-brr-primary w-100 m-0 flex-column align-items-center'>
            <Image className='col-md-2 me-5 col-6 mb-4' src={logo} style={{ "maxWidth": "250px", "maxHeight": "80px" }} />
            <div className='col-md-1 col-8'>
                <h2>Menu</h2>
                <Nav.Link href="/" className='text-white'>
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className='px-1'>Inicio</span>
                </Nav.Link>
            </div>
            <div className='col-md-2 col-8'>
                <h2>Área de Atuação</h2>
                <Nav.Link href="/#/know-more/health" className='text-white'>
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className='px-1'>Direito a Saúde</span>
                </Nav.Link>
                <Nav.Link href="/#/know-more/doctors" className='text-white'>
                    <i className="fa-solid fa-chevron-right"></i>
                    <span className='px-1'>Direito Médico</span>
                </Nav.Link>
            </div>
            <div className='col-md-2 col-8'>
                <h2>Contatos</h2>
                <p>
                    <i className="fa-brands fa-whatsapp"></i>
                    <span className='px-1'>(34) 99126-9126</span>
                </p>
                <p>
                    <i className="fa-solid fa-envelope"></i>
                    <span className='px-1'>atendimento@brr.adv.br</span>
                </p>
                <p>
                    <i className="fa-solid fa-location-dot"></i>
                    <span className='px-1'>Avenida Cesário Alvim, 3160, sala 18 - Bairro Brasil, Uberlândia/MG | CEP 38400-696</span>
                </p>
                <p> Bruna Buiatte Andrade (OAB/MG 152.360) </p>
                <p> Suiany Rosa Rodrigues (OAB/MG 140.599) </p>                
            </div>
        </Nav>
    );
}

export default BRRFooter;