import '@fortawesome/fontawesome-free/css/all.css';
import {
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { Navigate } from 'react-router';
import { useEffect } from "react";
import { useLocation } from "react-router";
import Home from "./pages/Home";
import Blog from './pages/Blog';
import KnowMore from './pages/KnowMore';
import WhoWeAre from './pages/WhoWeAre';
import BRRWhatsappbutton from './components/BRRWhatsappbutton';
import BRRToolbar from "./components/BRRToolbar";
import BRRFooter from './components/BRRFooter';
import BlogPost from './pages/BlogPost';
import BRRPostList from './pages/Blog/components/BRRPostList';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <BRRToolbar />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/know-more/:alias" element={<KnowMore />} exact />
        <Route path="/who-we-are" element={<WhoWeAre />} exact />
        <Route path="/blog" element={<Blog />} exact >
          <Route index element={<BRRPostList />} />
          <Route path="/blog/:id" element={<BlogPost/>} exact />
        </Route>
      </Routes>
      <BRRFooter />
      <BRRWhatsappbutton />
    </Router>
  );
}

export default App;

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{props.children}</>
};