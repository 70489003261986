import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BRRTitle from '../../../components/BBRRTitle';
import BRRActionAreaFigures from './BRRActionAreaFigures';

const healthItems = [
    {
        content: "Auxílio no fornecimento de próteses e órteses",
        icon: "fa-solid fa-suitcase-medical"
    },
    {
        content: "Fornecimento de medicamentos de alto custo pelo SUS ou pelo plano de saúde",
        icon: "fa-solid fa-pills"
    },
    {
        content: "Liberação de tratamentos fora do Rol da ANS pelo plano de saúde",
        icon: "fa-solid fa-user-doctor"
    },
    {
        content: "Negativa de internação e procedimentos cirúrgicos",
        icon: "fa-solid fa-bed-pulse"
    },
    {
        content: "Negativa de tratamento por carência contratual",
        icon: "fa-solid fa-file-signature"
    },
    {
        content: "Exclusão de dependentes pelo falecimento do titular do plano de saúde",
        icon: "fa-solid fa-shield-heart"
    }
]

const doctorsItems = [
    {
        content: "Consultoria Jurídica presencial e remota",
        icon: "fa-solid fa-laptop-medical"
    },
    {
        content: "Defesa em Conselhos de Instituições Hospitalares",
        icon: "fa-solid fa-dollar-sign"
    },
    {
        content: "Defesa Judicial de médicos e Odontólogos",
        icon: "fa-solid fa-syringe"
    },
    {
        content: "Compliance médico para consultórios, clínicas e hospitais",
        icon: "fa-solid fa-hospital"
    },
    {
        content: "Ações de auxílio moradia e prorrogação do FIES",
        icon: "fa-solid fa-house"
    },
    {
        content: "Ações de cobrança",
        icon: "fa-solid fa-money-bill"
    },

]

function BRRActionArea() {
    return (
        <div id="action-area">
            <BRRTitle title="Áreas de atuação"/>
            <BRRActionAreaFigures figures={healthItems} title="Direito da Saúde" alias="health" />
            <BRRActionAreaFigures figures={doctorsItems} title="Direito Médico" alias="doctors" />
        </div>
    );
}

export default BRRActionArea;
