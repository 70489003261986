import BRRSlogan from "./components/BRRSlogan";
import BRRActionArea from "./components/BRRActionArea";
import BRRWhoWeAre from './components/BRRWhoWeAre';
import BRRDifferentials from './components/BRRDifferentials';

function Home() {
  return (
    <div>
      <BRRSlogan />
      <BRRActionArea />
      <BRRWhoWeAre />
      <BRRDifferentials />
    </div>
  );
}

export default Home;
