import { Button } from 'react-bootstrap';
import Figure from 'react-bootstrap/Figure';
import { useNavigate } from 'react-router-dom';
import BBRRSubtitle from '../../../components/BBRRSubtitle';

function BRRActionAreaFigures({ figures, title, alias }) {
    const navigate = useNavigate();

    return (
        <div className='d-flex flex-column justify-content-center align-items-center mt-5'>
            <BBRRSubtitle title={title}/>
            <div className='mx-5 d-flex flex-wrap flex-md-row align-items-md-start align-items-center flex-column'>
                {figures.map((item, index) =>
                    <Figure key={index} className="col-4 d-flex flex-column justify-content-center align-items-center">
                        <i className={"text-brr-primary p-3 " + item.icon} style={{"fontSize": "80px"}}></i>
                        <Figure.Caption className='fs-6 fw-bolder text-dark text-center' style={{ "maxWidth": "250px" }}>
                            {item.content}
                        </Figure.Caption>
                    </Figure>
                )}
            </div>
            <Button variant="dark" className='mt-5 fw-bolder text-white' onClick={() => navigate(`know-more/${alias}`)}>Saiba mais</Button>
        </div>
    );
}

export default BRRActionAreaFigures;
