import { useEffect, useState } from "react";
import BRRPostList from "./components/BRRPostList";
import BRRRightSection from "./components/BRRRightSection";
import { getCategories, getPostsList } from "../../services/BlogService";
import { Outlet } from "react-router-dom";

function Blog() {
  const [recetPosts, setRecentPosts] = useState([])
  const [categories, setCategories] = useState([])
  const [archives, setArchives] = useState([])

  useEffect(() => {
    init();
  }, [])

  async function init() {
    const postList = await getPostsList();
    mountRecentPosts(postList)
    mountArchives(postList);
    const categoryList = await getCategories();
    mountCategories(categoryList);
  }

  function mountArchives(posts) {
    const monthNames = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
    const dates = [];
    posts.forEach(post => {
      const date = new Date(post.date);
      const item = {
        label: `${monthNames[date.getMonth()]} - ${date.getFullYear()}`,
        date: date,
        alias: `${date.getMonth()}:${date.getFullYear()}`
      }
      if (dates.find(el => el.alias == item.alias)) {
        return
      }
      dates.push(item)
    })
    setArchives(dates);
  }

  function mountCategories(categories) {
    const found = categories.find(category => category.id == 1);
    const index = categories.indexOf(found);
    categories[index].name = "Sem categoria";
    setCategories(categories);
  }

  function mountRecentPosts(posts) {
    const recentPosts = posts.sort((postA, postB) => {
      const dateA = new Date(postA.date);
      const dateB = new Date(postB.date);
      return dateA.getMilliseconds() - dateB.getMilliseconds();
    }).slice(0, 5)

    setRecentPosts(recentPosts);
  }

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap m-1 m-lg-5 justify-content-center">
      <div className="col-lg-8 col-12 me-lg-5 me-1">
        <Outlet/>
      </div>
      <BRRRightSection className="col-3" posts={recetPosts} categories={categories} archives={archives}></BRRRightSection>
    </div>
  );
}

export default Blog;
