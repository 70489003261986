function BRRWhatsappbutton() {
    const size = {
        width: "70px",
        height: "70px"
    }
    const style = {
        position: "fixed",
        bottom: "25px",
        right: "25px",
        "borderRadius": "50px",
        background: "#49E670",
        ...size
    }

    return (
        <a href="https://wa.me/5534991269126?text=" target="_blank" data-form="chaty-form-0-Whatsapp" data-hover="Fale conosco" style={style}>
            <i className="fa-brands fa-whatsapp text-white p-3" style={{"fontSize": "40px"}}></i>
        </a>
    );
}

export default BRRWhatsappbutton;

