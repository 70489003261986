import { Button } from 'react-bootstrap';
import './slogan.css'

function BRRSlogan() {
    return (
        <div className='w-100 slogan' id="home" >
            <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center text-white' style={{"background-color": "rgba(0, 97, 114, 0.75)"}}>
                <div className='h-50 row' style={{ width: "80%" }}>
                    <h1 className='mb-5'>Do paciente ao médico, seu direito é nossa prioridade</h1>
                    <Button variant="outline-secondary" className='border-white mt-5' style={{ width: "auto" }}>
                        <a href="https://wa.me/5534991269126?text=" target="_blank" data-form="chaty-form-0-Whatsapp" data-hover="Fale conosco" className="text-white" style={{"text-decoration": "none"}}>
                            Fale conosco
                        </a>
                    </Button>
                </div>
            </div >
        </div>
    );
}

export default BRRSlogan;