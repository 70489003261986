import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

function BRRBanner({title}) {
    const style = {
        height: "150px"
    }

    return (
        <div className='w-100 bg-brr-primary d-flex flex-column justify-content-center align-items-center text-white text-center mb-5' style={style}>
            <div className='h-50 row gy-3' style={{width: "80%"}}>
                <h1>{title}</h1>
            </div>
        </div>
    );
}

export default BRRBanner;