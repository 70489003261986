import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getPost } from "../../services/BlogService";
import { Button } from "react-bootstrap";

function BlogPost() {
  const location = useLocation();
  const [ post, setPost ] = useState({});
  let { id } = useParams();

  useEffect(() => {
    init()
  }, [location.pathname, id])

  async function init() {
    const serchedPost = await getPost(id)
    setPost(serchedPost);
  }

  return (
    <div className="d-flex justify-content-center">
      <div className="my-lg-5 m-1 col-lg-12 col-9">
        <h1 className="mb-lg-5 mb-1" style={{fontSize: "2rem"}}>{post.title}</h1>
        <div dangerouslySetInnerHTML={{__html: post.content}}></div>
        <Button variant="secondary" className='mt-5' style={{ width: "auto" }}>
            <a href="https://wa.me/5534991269126?text=" target="_blank" data-form="chaty-form-0-Whatsapp" data-hover="Fale conosco" className="text-white" style={{textDecoration: "none"}}>
                Fale conosco
            </a>
        </Button>
      </div>
    </div>
  );
}

export default BlogPost;
