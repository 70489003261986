import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./style.css"
import BRRBanner from "../../components/BRRBanner";

const contents = {
  health: {
    title: "Direito da Saúde",
    image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif",
    content: (<p>
      Nós somos um escritório especializado no Direito da Saúde e trabalhamos incansavelmente buscando todos os meios e soluções para que seus direitos sejam integralmente respeitados.
      <br />
      <br />
      Atuamos em demandas judiciais relacionadas à negativa de fornecimento de medicamentos de alto custo, negativa de realização de cirurgias, tratamentos e internações, recusa de fornecimento de próteses, órteses e demais materiais e insumos necessários para a realização de procedimentos e cirurgias, tanto contra os planos de saúde, como contra o Poder Público.
      <br />
      <br />
      Trabalhamos também para concretizar decisões médicas que estabeleçam a necessidade de assistência domiciliar “home care”, bem como na defesa dos direitos dos portadores de diversas síndromes, para a efetivação das terapias e tratamentos necessários para um bom desenvolvimento e qualidade de vida.
      <br />
      <br />
      Atuamos também em casos relacionados à exclusão de usuários e cancelamentos de contratos, negativa de tratamento em virtude de carência, assim como reembolso de despesas médicas.
      <br />
      <br />
      Caso tenha qualquer dúvida sobre o seu direito, contate-nos e estaremos prontos para te atender.
    </p>)
  },
  doctors: {
    title: "Direito Médico",
    image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif",
    content: (<p>
      Nós do BRR Advogados temos ampla atuação no Direito Médico, transitando nas esferas ético-profissional, administrativo e judicial, sempre buscando atender os anseios da classe e garantindo que não haja abusos ou prejuízos processuais.
      <br />
      <br />
      Nossa atuação inclui consultoria e assessoria preventiva, elaboração e revisão de contratos, termos de consentimento, termos de recusa de tratamento, entre outros.
      <br />
      <br />
      Orientações ético-legais relativas ao exercício da profissão, bem como atuação em Sindicância frente aos Conselhos Regionais e Federal, e Processo Ético Profissional perante os Conselhos Regionais e Federal.
      <br />
      <br />
      Da mesma maneira, a atuação também se estende ao âmbito judicial em casos envolvendo responsabilidade civil, na relação médico-paciente e outras demandas que possam advir do exercício da profissão.
      <br />
      <br />
      Além disso, o escritório também opera em ações pleiteando auxílio moradia, prorrogação e suspensão das parcelas do FIES.
      <br />
      <br />
      Seja qual for sua demanda estaremos prontos para lhe atender.
    </p>)
  },

}

function KnowMore() {
  let { alias } = useParams();
  const [content, setContent] = useState(contents[alias])

  useEffect(() => {
    setContent(contents[alias])
  }, [alias])

  return (
    <div>
      <BRRBanner title={content.title} />
      <div className="row w-100 fs-4 text-justify know-more-content">
        {content.content}
      </div>
    </div>
  );
}

export default KnowMore;
