import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPostsList } from '../../../services/BlogService';
import BRRPostListItem from './BRRPostListItem';
import BRRPaginator from './BRRPaginator';
import { Button } from 'react-bootstrap';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function BRRPostList() {
    const [posts, setPosts] = useState([])
    const [page, setPage] = useState(1);
    const [hasMorePosts, toggleHasMorePosts] = useState(false);
    const [onLoading, loading] = useState(false);
    const { search } = useLocation();
    let query = useQuery();

    const archive = query.get("archive")
    const category = query.get("category")
    const perPage = 10;

    useEffect(() => {
        searchPosts()
    }, [])

    async function searchPosts(currentPage = 1) {
        const currentPerPage = currentPage * perPage
        loading(true)
        const res = await getPostsList(currentPerPage);
        toggleHasMorePosts(res.length == currentPerPage && res.length != posts.length);
        setPosts(res);
        loading(false)
    }

    async function loadMore() {
        const currentPage = page+1
        searchPosts(currentPage)
        setPage(currentPage)
    }

    function filteredPosts() {
        if (archive) {
            return posts.filter(post => {
                const date = new Date(post.date)
                const [month, year] = archive.split(":");
                return date.getFullYear() == parseInt(year) && date.getMonth() == parseInt(month)
            })
        }
        if (category) {
            return posts.filter(post => post.categories?.some(cat => cat == category))
        }
        return posts
    }

    return (
        <div className='mb-5 mx-2'>
            {filteredPosts().map((post, index) => <BRRPostListItem {...post} key={index}></BRRPostListItem>)}
            <Button disabled={!hasMorePosts || onLoading} className='mx-5 my-5' color='primary' size='lg' onClick={loadMore}>Ver mais</Button>
        </div>
    );
}

export default BRRPostList;
