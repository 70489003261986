import { Figure } from "react-bootstrap";

function BRRFigure({ icon, content, title }) {
    return (

        <Figure className="col-md-4 col-12 d-flex flex-column align-items-center px-3">
            <i className={"text-brr-primary p-3 " + icon} style={{ "fontSize": "80px" }}></i>
            <Figure.Caption className='fs-1 fw-bolder text-dark mb-3 text-center'>
                {title}
            </Figure.Caption>
            <Figure.Caption className='fs-5 text-dark text-justify'>
                {content}
            </Figure.Caption>
        </Figure>
    );
}

export default BRRFigure;
