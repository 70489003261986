import { Link } from 'react-router-dom';


function BRRRightSection({ posts, archives, categories }) {

  return (
    <div className='mx-md-1 mx-5 my-2'>
      <div className="d-flex flex-column justify-content-between">
        <h3>Posts recentes</h3>
        {posts?.map((post, index) => (
          <Link className='fw-bold text-decoration-none text-brr-primary' to={`${post.id}`} key={index}>{post.title}</Link>
        ))}
      </div>
      <div className="mt-4 d-flex flex-column">
        <h3>Arquivos</h3>
        {archives?.map((archive, index) => <Link to={`/blog?archive=${archive.alias}`} className='fw-bold text-decoration-none text-brr-primary' key={index}>{archive.label}</Link>)}
      </div>
      <div className="mt-4 d-flex flex-column">
        <h3>Categorias</h3>
        {categories?.map((category, index) => <Link to={`/blog?category=${category.id}`} className='fw-bold text-decoration-none text-brr-primary' key={index}>{category.name}</Link>)}
      </div>
    </div>
  );
}

export default BRRRightSection;
