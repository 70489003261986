import { NavDropdown } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from 'react-router-dom';
import Image from 'react-bootstrap/Image'
import logo from '../assets/brr-logo.png';

function BRRToolbar() {
  const navigate = useNavigate();

  return (
    <div>
      <div className='bg-brr-primary d-flex justify-content-end px-5 py-1'>
        <a target="_blank" href='http://facebook.com/brr.advogadosudi'>
          <i className="fa-brands fa-facebook text-white fs-4 px-2"></i>
        </a>
        <a target="_blank" href='https://www.instagram.com/brr_advogados'>
          <i className="fa-brands fa-instagram text-white fs-4 px-2"></i>
        </a>
        <a target="_blank" href='https://www.youtube.com/channel/UCfK1zZmirHI4Kal3-QSpwJQ'>
          <i className="fa-brands fa-youtube text-white fs-4 px-2"></i>
        </a>
        <a target="_blank" href='https://www.tiktok.com/@brr_advogados?_t=8Xk1DqaZXwD&_r=1'>
          <i className="fa-brands fa-tiktok text-white fs-4 px-2"></i>
        </a>
        <a target="_blank" href='mailto:atendimento@brr.adv.br'>
          <i className="fa-solid fa-envelope text-white fs-4 px-2"></i>
        </a>
      </div>
      <Navbar bg="light" expand="lg" className='px-3 py-5 ps-5'>
          <Navbar.Brand href="/">
            <Image src={logo} style={{ height: "80px" }} />
          </Navbar.Brand>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto d-flex justify-content-end w-100">
              <Nav.Link href="/">Inicio</Nav.Link>
              <Nav.Link href="/#/action-area"></Nav.Link>
              <NavDropdown title="Área de atuação" id="basic-nav-dropdown">
                <NavDropdown.Item href="/#/know-more/health">Direito da Saúde</NavDropdown.Item>
                <NavDropdown.Item href="/#/know-more/doctors">Direito Médico</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/#/who-we-are">Quem Somos</Nav.Link>
              <Nav.Link href="/#/blog">Blog</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default BRRToolbar;