
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

function BBRRSubtitle({ title, dark }) {
    const textColor = dark? "text-white": "text-dark"

    return (
        <div className='d-flex flex-column align-items-center mb-5'>
            <h1 className={`fw-bolder ${textColor}`}>{title}</h1>
            <span className='bg-warning' style={{ height: "5px", width: "50px", display: "block" }} />
        </div>
    );
}

export default BBRRSubtitle;

