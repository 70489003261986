import { Button, Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BRRTitle from "../../../components/BBRRTitle";

const images = [
    {
        title: "First slide",
        image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif"
    },
    {
        title: "First slide",
        image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif"
    },
    {
        title: "First slide",
        image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif"
    },
    {
        title: "First slide",
        image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif"
    },
    {
        title: "First slide",
        image: "https://escritorioma.com/wp-content/uploads/2021/03/01-768x768.gif"
    },
]

function BRRWhoWeAre() {
    const navigate = useNavigate();

    return (
        <div className="d-flex justify-content-center mt-5" id="who-we-are">
            <div className="col-12 p-5">
                <BRRTitle title="Quem somos" />

                <p className="fs-5 text-dark text-justify">
                    O escritório Buiatte Rodrigues Rosa Advogados – BRR, foi fundado pelas doutoras Bruna Buiatte Andrade (OAB/MG 152.360) e Suiany Rosa Rodrigues (OAB/MG 140.599) e é reconhecido pela atuação especializada em Direito da Saúde e Direito Médico.
                    <br />
                    <br />
                    Estamos no Município de Uberlândia/MG, mas devido a atuação em vários estados brasileiros não demorou para instaurarmos melhorias para atendimento 100% (cem por cento) virtual. A inovação traz aos nossos clientes as vantagens de um atendimento rápido e, sobretudo, eficiente, com excelentes resultados em qualquer lugar do país.
                    <br />
                    <br />
                    Nossa equipe é composta por especialistas nas áreas, que além de atuar com eficácia estão prontos para te defender e buscar a preservação dos seus direitos.
                </p>
                <Button variant="dark" className='mt-2 fw-bolder text-white' onClick={() => navigate("/who-we-are")}>Saiba mais</Button>
            </div>
        </div>
    );
}

export default BRRWhoWeAre;

