import axios from "axios";

const api = axios.create({
    baseURL: "https://brr.adv.br/wp/wp-json/wp/v2",
});

function formatPost(post) {
    return {
        id: post.id,
        title: post.title.rendered,
        date: post.date,
        content: post.content.rendered,
        excerpt: post.excerpt.rendered,
        categories: post.categories
    }
}

export const getPostsList = async (perPage) => {
    let query = "/posts"
    if (perPage) {
        query = `${query}?per_page=${perPage}`
    }
    return (await api.get(query)).data.map(formatPost);
}

export const getCategories = async () => {
    return (await api.get("/categories")).data.map(item => {
        return {
            id: item.id,
            name: item.name,
            description: item.description
        }
    });
}

export const getPost = async (id) => {
    const res = (await api.get(`/posts/${id}`)).data
    return formatPost(res);
}



export default api;