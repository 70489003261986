import { Figure } from "react-bootstrap";
import BRRTitle from "../../../components/BBRRTitle";
import image1 from '../../../assets/foto1.jpeg'
import image2 from '../../../assets/foto2.jpeg'
import image3 from '../../../assets/foto3.jpeg'
import image4 from '../../../assets/foto4.jpeg'

const diffentials = [
    {
        icon: "fa-solid fa-computer",
        title: "Atendimento físico e digital"
    },
    {
        icon: "fa-brands fa-whatsapp",
        title: "Suporte jurídico por whatsapp"
    },
    {
        icon: "fa-solid fa-handshake",
        title: "Atendimento especializado e personalizado"
    },
    {
        icon: "fa-solid fa-circle-check",
        title: "Eficiência no acompanhamento de cumprimento de liminares"
    },
    {
        icon: "fa-solid fa-scale-balanced",
        title: "Acompanhamento até o final do processo"
    },
    {
        icon: "fa-solid fa-clock",
        title: "Flexibilidade de horário"
    },
]

const style = {
    "background-image": `url(${image4})`,
    "background-position": "center center",
    "background-repeat": "no-repeat",
    "background-size": "cover",
}

function BRRDifferentials() {
    return (
        <div className="w-100" style={style}>
            <div className="w-100 h-100" style={{background: "rgba(var(--bs-dark-rgb), 0.75)"}}>
                <BRRTitle title="Nossos diferenciais" dark={true} />
                <div className="d-flex flex-wrap flex-md-row justify-content-center align-items-center flex-column w-100 ps-md-4">
                    {
                        diffentials.map((item, index) =>
                            <div className="col-10 col-md-3 mx-md-5">
                                <Figure key={index} className="d-flex flex-column justify-content-center align-items-center rounded p-5" style={{ "height": "300px", background: "rgba(108, 117, 125, 0.4)" }}>
                                    <i className={"text-brr-primary p-3 " + item.icon} style={{ "fontSize": "80px" }}></i>
                                    <Figure.Caption className='fs-4 fw-bolder text-white mb-3 text-center'>
                                        {item.title}
                                    </Figure.Caption>
                                </Figure>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default BRRDifferentials;