import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function BRRPostListItem({ title, content, excerpt, id }) {
    const navigate = useNavigate();

    return (
        <div className='mx-5 py-4' style={{borderBottom: "1px solid lightgrey"}}>
            <h2 className='pb-2'>{title}</h2>
            <div className='pb-2' dangerouslySetInnerHTML={{__html: excerpt}}></div>
            <Button variant='outline-secondary' onClick={() => navigate(id.toString())}>
                Continue lendo
            </Button>
        </div>
    );
}

export default BRRPostListItem;
