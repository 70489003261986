import BRRBanner from "../../components/BRRBanner";
import BRRFigure from "./components/BRRFigure";
import "./style.css"

function WhoWeAre() {
    return (
        <div>
            <BRRBanner title="Quem Somos nós"/>
            <p className="fs-5 text-dark text-justify who-we-are-content">
                O escritório Buiatte Rodrigues Rosa Advogados – BRR, foi fundado pelas doutoras Bruna Buiatte Andrade (<a target="_blank" href='https://www.oabmg.org.br/Consulta/Home/ConsultaInscrito/160986'>OAB/MG 152.360</a>) e Suiany Rosa Rodrigues (<a target="_blank" href='https://www.oabmg.org.br/Consulta/Home/ConsultaInscrito/148483'>OAB/MG 140.599</a>) e é reconhecido pela atuação especializada em Direito da Saúde e Direito Médico.
                <br />
                <br />
                O BBR Advogados está localizado no Município de Uberlândia/MG, mas devido a atuação em vários estados brasileiros não demorou para instaurarmos melhorias de acesso digital, para atendimento 100% (cem por cento) virtual. A inovação traz aos nossos clientes uma atuação perspicaz, rápida e, sobretudo, eficiente, com excelentes resultados em qualquer lugar do país.
                <br />
                <br />
                Nossa equipe é composta por especialistas nas áreas, que além de atuar com eficácia está pronto para te defender e buscar a preservação dos seus mais importantes direitos: a vida e a saúde.
            </p>
            <div className="d-flex flex-md-row flex-column justify-space-between gx-5 w-100 who-we-are-content">
                <BRRFigure icon="fa-solid fa-file-pen" title="Missão" content="Oferecer aos nossos clientes uma advocacia de excelência, humanizada, eficiente e célere, buscando combater as abusividades sofridas por eles especialmente no âmbito da saúde." />
                <BRRFigure icon="fa-solid fa-chart-simple" title="Visão" content="A preservação dos mais importantes direitos do ser humano: a vida e a saúde é o que nos move diariamente. Por isso nosso empenho para oferecer um serviço de qualidade, individualizado e rápido." />
                <BRRFigure icon="fa-solid fa-star" title="Valores" content="O BRR Advogados tem enorme respeito por seus clientes e, por isso, atua com total ética e transparência na defesa dos seus direitos. Aqui, vocês são acolhidos e se tornam parte do nosso escritório e da nossa jornada." />
            </div>
        </div>
    );
}

export default WhoWeAre;
